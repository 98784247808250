import { config } from '@/config'
export const cesiumInit = {
    data() {
        return {
            viewer: null,
            btnSwitch: true,
            kmlArray: [],
            czmlArray: [],
            GPXArray: [],
            imgMaptianditu: null, // 叠加影像服务
            iboMaptainditu: null, // 叠加国界服务
            ciaMaptainditu: null, // 叠加注记服务
            terMaptainditu: null, // 叠加晕渲图
            provider: null, // 叠加地形服务
            NotesBool: true, // 标记注记是否显示，true为未显示
            Nationalline: null, // 记录国界线
            Noteshide:null, // 记录三维注记
            DizzinessBool: true, // 标记晕眩图是否显示
            Dizziness: null, // 记录晕眩图的叠加
            LightAndShadowBool: true,  // 标记是否加载光影
        };
    },
    methods: {
        cesiumMap(el){
            Cesium.Ion.defaultAccessToken = config.cesiumToken
            this.viewer = new Cesium.Viewer(el, {
                // terrainProvider: Cesium.createWorldTerrain(),  // 使用cesium在线lon地形，此参数导致cesium加载不出来
                
                // terrainProvider: Cesium.createWorldTerrain({
                //     requestWaterMask : true,
                //     requestVertexNormals : true
                // }),
                shouldAnimate: true,
                selectionIndicator: false,
                infoBox: false,
                homeButton: false, // 不显示home键
                fullscreenButton: false, //是否显示全屏按钮
                geocoder: false, //是否显示geocoder小器件，右上角查询按钮
                navigationHelpButton: false, //是否显示右上角的帮助按钮
                fullscreenButton: false, //是否显示全屏按钮
                // baseLayerPicker: false, //右上角的图层选择按钮
                sceneModePicker:false, //  是否显示切换2D、3D 和 Columbus View (CV) 模式。
                baseLayerPicker: false,//隐藏默认底图选择控件
                imageryProvider: new Cesium.BingMapsImageryProvider({
                    url: 'https://dev.virtualearth.net',
                    key: config.bingMapKey,
                    mapStyle: Cesium.BingMapsStyle.AERIAL
                }), // 设置默认底图 加载bing卫星影像
            });
            // alert(2)
            this.viewer._cesiumWidget._creditContainer.style.display = "none";// 隐藏版权
            this.viewer.animation.container.style.visibility = 'hidden' // 不显示动画控件
            this.viewer.timeline.container.style.visibility = 'hidden' // 不显示时间线控件

            // 默认加载bing地图
            // this.setBingMap()

            // 将三维球定位到中国
            this.settingViewToChina()
            this.handler(this.viewer)
            // this.setStyles()
            // const _this = this
            // 主键返回定位到中国
            $(".cesium-home-button").click(() => {
                this.settingViewToChina()
            })
    
            /** 
             * 变量初始化
             */ 
            // 指北针开启或关闭功能,默认关闭
            this.btnSwitch = true;
            this.kmlArray = [];
            this.czmlArray = [];
            this.GPXArray = [];

            // 标记注记是否显示，true为未显示
            this.NotesBool = true;
            //记录国界线
            this.Nationalline = null;
            //记录三维注记
            this.Noteshide = null;
            //标记晕眩图是否显示
            this.DizzinessBool = true;
            //记录晕眩图的叠加
            this.Dizziness;
            // 标记是否加载光影
            this.LightAndShadowBool = true;
            /**
             * 地形API start
             */

            //天地图服务域名
            var tdtUrl = 'https://t{s}.tianditu.gov.cn/';
            // 服务负载子域
            var subdomains = ['0', '1', '2', '3', '4', '5', '6', '7'];
            // 叠加影像服务
            this.imgMaptianditu = new Cesium.UrlTemplateImageryProvider({
                url: tdtUrl + 'DataServer?T=img_w&x={x}&y={y}&l={z}&tk=' + config.tMapKey,
                subdomains: subdomains,
                tilingScheme: new Cesium.WebMercatorTilingScheme(),
                maximumLevel: 18,
            });

            // 叠加国界服务
            this.iboMaptainditu = new Cesium.UrlTemplateImageryProvider({
                url: tdtUrl + 'DataServer?T=ibo_w&x={x}&y={y}&l={z}&tk=' + config.tMapKey,
                subdomains: subdomains,
                tilingScheme: new Cesium.WebMercatorTilingScheme(),
                maximumLevel: 10
            });
            
            // 叠加注记服务
            this.ciaMaptainditu = new Cesium.UrlTemplateImageryProvider({
                url: tdtUrl + 'DataServer?T=cia_w&x={x}&y={y}&l={z}&tk=' + config.tMapKey,
                subdomains: subdomains,
                tilingScheme: new Cesium.WebMercatorTilingScheme(),
                maximumLevel: 10,
            });

            // 叠加晕渲图
            this.terMaptainditu = new Cesium.UrlTemplateImageryProvider({
                url: tdtUrl + 'DataServer?T=ter_w&x={x}&y={y}&l={z}&tk=' + config.tMapKey,
                subdomains: subdomains,
                tilingScheme: new Cesium.WebMercatorTilingScheme(),
                maximumLevel: 10,
            });

            // 叠加地形服务
            // var terrainUrls = new Array();

            // for (var i = 0; i < subdomains.length; i++) {
            //     var url = tdtUrl.replace('{s}', subdomains[i]) + 'mapservice/swdx?tk=' + config.tMapKey;
            //     terrainUrls.push(url);
            // }

            // this.provider = new Cesium.GeoTerrainProvider({
            //     urls: terrainUrls
            // });
            /** 地形API end */

        },
        pointCamera(lng, lat, height = 500, duration = 3) {
            this.viewer.camera.flyTo({
                duration,
                destination: Cesium.Cartesian3.fromDegrees(lng, lat, height)
            })
        },

        // 加载天地图
        setTMap() {
            this.viewer.imageryLayers.removeAll();
            this.viewer.imageryLayers.addImageryProvider(this.imgMaptianditu);
            // this.viewer.terrainProvider = this.provider;
            this.raiseToTop();
        },

        // 加载bing Map 
        setBingMap() {
            //加载bing卫星影像
            var bing = new Cesium.BingMapsImageryProvider({
                url: 'https://dev.virtualearth.net',
                key: config.bingMapKey,
                mapStyle: Cesium.BingMapsStyle.AERIAL
            });
            //加载bing影像
            // var terrLayer = new Cesium.CesiumTerrainProvider({
            //     url: "https://lab.earthsdk.com/terrain/42752d50ac1f11e99dbd8fd044883638", //地址记得换成自己的地形数据地址
            //     requestVertexNormals: true
            // })
            this.viewer.imageryLayers.removeAll();
            this.viewer.imageryLayers.addImageryProvider(bing);
            // this.viewer.scene.terrainProvider = terrLayer;
            this.raiseToTop();
        },
        raiseToTop() {
            if (this.NotesBool == false) {
                this.Nationalline = this.viewer.imageryLayers.addImageryProvider(this.iboMaptainditu);
                this.Noteshide = this.viewer.imageryLayers.addImageryProvider(this.ciaMaptainditu);
            }
        },
        // 加载地形标注
        notesMap() {
            if (this.NotesBool == true) {
                this.Nationalline = this.viewer.imageryLayers.addImageryProvider(this.iboMaptainditu);
                this.Noteshide = this.viewer.imageryLayers.addImageryProvider(this.ciaMaptainditu);
                this.NotesBool = !this.NotesBool;
            } else if (this.NotesBool == false) {
                this.NotesBool = !this.NotesBool;
                this.viewer.imageryLayers.remove(this.Nationalline, true);
                this.viewer.imageryLayers.remove(this.Noteshide, true);
            }
        },

        // 加载晕眩图
        dizzinessMap() {
            if (this.DizzinessBool == true) {
                this.Dizziness = this.viewer.imageryLayers.addImageryProvider(this.terMaptainditu);
                this.DizzinessBool = !this.DizzinessBool;
            } else if (this.DizzinessBool == false) {
                this.DizzinessBool = !this.DizzinessBool;
                this.viewer.imageryLayers.remove(this.Dizziness, true);
            }
        },

        // 加载光影
        lightAndShadow() {
            this.viewer.scene.globe.enableLighting = this.LightAndShadowBool;
            this.viewer.shadows = this.LightAndShadowBool;
            this.LightAndShadowBool = !this.LightAndShadowBool;
        },
    
        // 将三维球定位到中国
        settingViewToChina() {
            // 将三维球定位到中国
            this.viewer.camera.flyTo({
                destination: Cesium.Cartesian3.fromDegrees(103.84, 31.15, 17850000),
                orientation: {
                    heading: Cesium.Math.toRadians(348.4202942851978),
                    pitch: Cesium.Math.toRadians(-89.74026687972041),
                    roll: Cesium.Math.toRadians(0)
                },
                complete: function callback() {
                    // 定位完成之后的回调函数
                }
            });
        },

        // setStyles
        setStyles() {
            // 修改 homebutton 和 二三纬切换的默认显示样式
            if(typeof(window.chrome.webview) && typeof(window.chrome.webview) != 'undefined') {
            // if () {
                window.chrome.webview.addEventListener('message', event => {
                    this.removeCzmlFileData();
                    this.removeKmlFileData();
                    if (event.data[0] == '<') {
                        var options = {
                            camera: this.viewer.scene.camera,
                            canvas: this.viewer.scene.canvas
                        }
                        let parser = new DOMParser();
                        let xmlObject = parser.parseFromString(event.data, "text/xml");
                        var geocachePromise = Cesium.KmlDataSource.load(xmlObject, options);

                        this.viewer.flyTo(geocachePromise, { duration: 8.0,
                            // 调整飞行完成后的摄像机视角
                            offset: new Cesium.HeadingPitchRange(0.0, Cesium.Math.toRadians(-90.0)) });

                        geocachePromise.then(function(dataSource) {
                            // Add the new data as entities to the viewer
                            viewer.dataSources.add(dataSource);
                            this.kmlArray.push(dataSource);
                        });
                    } else {
                        var dataSourcePromise = Cesium.CzmlDataSource.load(event.data);
                        dataSourcePromise.then(function(dataSource) {
                            this.czmlArray.push(dataSource);
                            this.viewer.dataSources.add(dataSource);
                            this.viewer.flyTo(dataSource, { duration: 8.0,
                            // 调整飞行完成后的摄像机视角
                            offset: new Cesium.HeadingPitchRange(0.0, Cesium.Math.toRadians(-90.0)) });
                        });
                    }
                });
            }
        },

        // 开启罗盘
        setNavigation() {
            if (!this.btnSwitch) {
                this.viewer.cesiumNavigation.destroy();
            } else {
                var navOptions = {};
                // 用于在使用重置导航重置地图视图时设置默认视图控制。接受的值是Cesium.Cartographic 和Cesium.Rectangle.
                navOptions.defaultResetView = Cesium.Rectangle.fromDegrees(110, 30, 2000000);
                // 用于启用或禁用罗盘。true是启用罗盘，false是禁用罗盘。默认值为true。如果将选项设置为false，则罗盘将不会添加到地图中。
                navOptions.enableCompass = this.btnSwitch;
                // 用于启用或禁用缩放控件。true是启用，false是禁用。默认值为true。如果将选项设置为false，则缩放控件 将不会添加到地图中。
                navOptions.enableZoomControls = this.btnSwitch;
                // 用于启用或禁用距离图例。true是启用，false是禁用。默认值为true。如果将选项设置为false，距离图例将不会添加到地图中。
                navOptions.enableDistanceLegend = this.btnSwitch;
                // 用于启用或禁用指南针外环。true是启用，false是禁用。默认值为true。如果将选项设置为false，则该环将可见但无效。
                navOptions.enableCompassOuterRing = this.btnSwitch;
        
                this.viewer.extend(Cesium.viewerCesiumNavigationMixin, navOptions);
            }
            this.btnSwitch = !this.btnSwitch;
            console.log(this.btnSwitch);
        },
        
        // 通过浏览的方式读取本地文件
        fileImport() {
            //获取读取我文件的File对象
            var selectedFile = document.getElementById('files').files[0];
            var temp = document.getElementById('files');
            // var name = selectedFile.name; //读取选中文件的文件名
            // var size = selectedFile.size; //读取选中文件的大小

            // console.log("文件名:" + name + "大小:" + size);
            var reader = new FileReader(); //这是核心,读取操作就是由它完成.

            reader.onload = function() {
                var options = {
                    camera: this.viewer.scene.camera,
                    canvas: this.viewer.scene.canvas
                }
                var geocachePromise = Cesium.KmlDataSource.load(reader.result, options);

                // this.viewer.flyTo(geocachePromise, {duration: 8.0});
                geocachePromise.then(function(dataSource) {
                    this.kmlArray.push(dataSource);
                    // console.log(this.kmlArray);
                    // Add the new data as entities to the viewer
                    this.viewer.dataSources.add(dataSource);
                    this.viewer.flyTo(dataSource, {
                        // 飞行时间8秒
                        duration: 8.0,
                        // 调整飞行完成后的摄像机视角
                        offset: new Cesium.HeadingPitchRange(0.0, Cesium.Math.toRadians(-90.0))
                    });
                });
            }
            reader.readAsDataURL(selectedFile); //读取文件的内容,也可以读取文件的URL
        },

        // 加载 czml 文件
        loadCZMLFile() {
            //获取读取我文件的File对象
            var selectedFile = document.getElementById('czmlFiles').files[0];
            var temp = document.getElementById('czmlFiles');
            var reader = new FileReader(); //这是核心,读取操作就是由它完成.

            reader.onload = function() {

                // console.log(reader.result);
                var dataSourcePromise = Cesium.CzmlDataSource.load(reader.result);
                dataSourcePromise.then(function(dataSource) {
                    this.czmlArray.push(dataSource);
                    this.viewer.dataSources.add(dataSource);
                    this.viewer.flyTo(dataSource, {
                        duration: 8.0,
                        // 调整飞行完成后的摄像机视角
                        offset: new Cesium.HeadingPitchRange(0.0, Cesium.Math.toRadians(-90.0))
                    })
                })
            }
            reader.readAsDataURL(selectedFile); //读取文件的内容,也可以读取文件的URL

        },
        // 销毁cesium实例
        removeCesium() {
            this.viewer && this.viewer.destroy()
            this.viewer = null
        },
        // 移除kml数据文件
        removeKmlFileData() {
            let fileInput = document.getElementById("files");
            fileInput.value = "";
            for (let i = 0; i < this.kmlArray.length; i++) {
                // console.log(this.kmlArray[i])
                this.viewer.dataSources.remove(this.kmlArray[i]);
            }
            // 将存储文件数组中的所有数据删除
            this.kmlArray = [];
        },
        // 移除czml数据文件
        removeCzmlFileData() {
            let fileInput = document.getElementById("czmlFiles");
            fileInput.value = "";
            for (let i = 0; i < this.czmlArray.length; i++) {
                // console.log(this.kmlArray[i])
                this.viewer.dataSources.remove(this.czmlArray[i]);
            }
            // 将存储文件数组中的所有数据删除
            this.czmlArray = [];
        },
        // 移除gpx数据文件
        removeGpxFileData() {
            for (let i = 0; i < this.GPXArray.length; i++) {
                // console.log(this.kmlArray[i])
                this.viewer.dataSources.remove(this.GPXArray[i]);
            }
            // 将存储文件数组中的所有数据删除
            this.GPXArray = [];
        },

        
        /**
         * 开启Cesium的抗锯齿
         * 开启抗锯齿可能会导致文字注记不清晰，但在一定程度上会消除线和面的锯齿
         */
        openFxaa() {
            if (Cesium.FeatureDetection.supportsImageRenderingPixelated()) {
                //判断是否支持图像渲染像素化处理
                this.viewer.resolutionScale = window.devicePixelRatio;
            }
            //开启抗锯齿
            this.viewer.scene.fxaa = true;
            this.viewer.scene.postProcessStages.fxaa.enabled = true;
        },

        
        /**
         * 添加经纬度网格
         * 返回一个数组，保存了经纬度网格线的entitie id
         */
        addLatLonPolyline(num) {
            const entities = this.viewer.entities;
            var str = new Array();
            //每隔20读绘制一条经度线和经度标注,自己控制间隔
            for (let lang = -180; lang <= 180; lang += num) {
                let text = "";
                if (lang === 0) {
                    text = "0";
                }
                text += lang === 0 ? "" : "" + lang + "°";
                if (lang === -180) {
                    text = "";
                }

                var id = entities.add({
                    position: Cesium.Cartesian3.fromDegrees(lang, 0),
                    polyline: {
                        positions: Cesium.Cartesian3.fromDegreesArray([
                            lang,
                            -90,
                            lang,
                            0,
                            lang,
                            90,
                        ]),
                        width: 1.0,
                        material: Cesium.Color.DODGERBLUE,
                    },
                    label: {
                        text: text,
                        verticalOrigin: Cesium.VerticalOrigin.TOP,
                        font: "12px sans-serif",
                        fillColor: Cesium.Color.DODGERBLUE,
                    },
                });
                str.push(id);
            }

            //纬度
            let langS = [];
            for (let lang = -180; lang <= 180; lang += num) {
                langS.push(lang);
            }
            //每隔10读绘制一条纬度线和纬度标注,自己控制间隔
            for (let lat = -90; lat <= 90; lat += num) {
                let text = "";
                text += "" + lat + "°";
                if (lat === 0) {
                    text = "";
                    var id = entities.add({
                        position: Cesium.Cartesian3.fromDegrees(0, lat),
                        polyline: {
                            positions: Cesium.Cartesian3.fromDegreesArray(
                                langS
                                .map((long) => {
                                    return [long, lat].join(",");
                                })
                                .join(",")
                                .split(",")
                                .map((item) => Number(item))
                            ),
                            width: 2.0,
                            material: Cesium.Color.RED,
                        },
                        label: {
                            text: text,
                            font: "14px sans-serif",
                            fillColor: Cesium.Color.DODGERBLUE,
                        },
                    });
                    var idS = entities.add({
                        position: Cesium.Cartesian3.fromDegrees(0, -23.433),
                        polyline: {
                            positions: Cesium.Cartesian3.fromDegreesArray(
                                langS
                                .map((long) => {
                                    return [long, -23.433].join(",");
                                })
                                .join(",")
                                .split(",")
                                .map((item) => Number(item))
                            ),
                            width: 1.5,
                            material: new Cesium.PolylineDashMaterialProperty({
                                color: Cesium.Color.DODGERBLUE
                            }),
                        },
                        label: {
                            text: "南回归线",
                            font: "14px sans-serif",
                            fillColor: Cesium.Color.DODGERBLUE,
                        },
                    });
                    var idN = entities.add({
                        position: Cesium.Cartesian3.fromDegrees(0, 23.433),
                        polyline: {
                            positions: Cesium.Cartesian3.fromDegreesArray(
                                langS
                                .map((long) => {
                                    return [long, 23.433].join(",");
                                })
                                .join(",")
                                .split(",")
                                .map((item) => Number(item))
                            ),
                            width: 1.5,
                            material: new Cesium.PolylineDashMaterialProperty({
                                color: Cesium.Color.DODGERBLUE
                            }),
                        },
                        label: {
                            text: "北回归线",
                            font: "14px sans-serif",
                            fillColor: Cesium.Color.DODGERBLUE,
                        },
                    });
                } else {
                    var id = entities.add({
                        position: Cesium.Cartesian3.fromDegrees(0, lat),
                        polyline: {
                            positions: Cesium.Cartesian3.fromDegreesArray(
                                langS
                                .map((long) => {
                                    return [long, lat].join(",");
                                })
                                .join(",")
                                .split(",")
                                .map((item) => Number(item))
                            ),
                            width: 1.0,
                            material: Cesium.Color.DODGERBLUE,
                        },
                        label: {
                            text: text,
                            font: "14px sans-serif",
                            fillColor: Cesium.Color.DODGERBLUE,
                        },
                    });
                }
                str.push(id);
            }
            str.push(idS);
            str.push(idN);
            return str;
        },

        removeLatLonPolyline(ids) {
            for (let i = 0; i < ids.length; i++) {
                this.viewer.entities.removeById(ids[i].id);
            }
        },

        // 放大地球
        handler(viewer) {
            // 双击放大地球
            var handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
            handler.setInputAction(function(event) {
                console.log('左键双击事件：', event.position);
                var ellipsoid = viewer.scene.globe.ellipsoid;
                var cartesian = viewer.scene.camera.pickEllipsoid(event.position, ellipsoid);

                if (cartesian) { //能获取，显示坐标
                    var cartographic = ellipsoid.cartesianToCartographic(cartesian);
                    var lon = Cesium.Math.toDegrees(cartographic.longitude).toFixed(2);
                    var lat = Cesium.Math.toDegrees(cartographic.latitude).toFixed(2);
                    var height = Math.ceil(viewer.camera.positionCartographic.height) / 1.8;
                    viewer.camera.flyTo({
                        destination: Cesium.Cartesian3.fromDegrees(lon, lat, height)
                    });
                }

            }, Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);

                    
            var rightDownTime = 0;
            // 右双击放大地球
            handler.setInputAction(function(event) {
                console.log('左键双击事件：', event.position);
                if (Date.now() - rightDownTime > 400) {
                    rightDownTime = Date.now();
                    return;
                }
                var ellipsoid = viewer.scene.globe.ellipsoid;
                var cartesian = viewer.scene.camera.pickEllipsoid(event.position, ellipsoid);
                if (cartesian) { //能获取，显示坐标
                    var cartographic = ellipsoid.cartesianToCartographic(cartesian);
                    var lon = Cesium.Math.toDegrees(cartographic.longitude).toFixed(2);
                    var lat = Cesium.Math.toDegrees(cartographic.latitude).toFixed(2);
                    var height = Math.ceil(viewer.camera.positionCartographic.height) * 1.8;
                    viewer.camera.flyTo({
                        destination: Cesium.Cartesian3.fromDegrees(lon, lat, height)
                    });
                }

            }, Cesium.ScreenSpaceEventType.RIGHT_CLICK);

        }
    }
}
export const drawTool = {
    data(){
        return {
            modal: {}
        }
    },
    methods: {
        drawInit(){
            const viewer = this.viewer
            // 广告牌的可渲染集合
            this.billboards = this.viewer.scene.primitives.add(new Cesium.BillboardCollection({
                scene: this.viewer.scene
            }))
            this.rotateBool = false // 是否旋转
            this.modal = {
                show: false,
                style: {},
                data: {},
                title: '',
                type: ''
            }
            // 事件，左键按下弹出信息框
            this.leftDownAction()
        },
        // 获取坐标
        setPosition(lng, lat, alt = 0) {
            return Cesium.Cartesian3.fromDegrees(lng, lat, alt)
        },
        addPoint(option) {
            const {
                id,
                name,
                lng,
                lat,
                width,
                height,
                ele = 500,
                showImage,
                image
            } = option
            this.viewer.entities.add({
                id,
                name,
                position: new Cesium.Cartesian3.fromDegrees(lng, lat, ele),
                point: {
                    color: Cesium.Color.RED, // 点的颜色
                    pixelSize: 0, // 点的大小
                    outlineColor: Cesium.Color.YELLOW, // 外环圈的颜色
                    outlineWidth: 1, // 外环圈的宽度
                },
                billboard: {
                    image: require(`../../public/static/images/model/${image}`),
                    width,
                    height,
                },
                data: option
            })
        },
        // 鼠标事件 左键单击
        leftDownAction() {
            const viewer = this.viewer
            viewer.screenSpaceEventHandler.setInputAction((movement) => {
                var picked = viewer.scene.pick(movement.position)
                if (Cesium.defined(picked) && picked.id.id) {
                    this.popupPosition(picked)
                } else {
                    return
                }
            }, Cesium.ScreenSpaceEventType.LEFT_CLICK)


        },
        // 设置弹框位置
        popupPosition(e) {
            let data = e.id.data
            //经纬度转为世界坐标
            let gisPosition = Cesium.Cartesian3.fromDegrees(
                data.lng,
                data.lat,
                (data.ele || 500)
            );
            Object.assign(this.modal, {
                show: true
            }) // 弹出信息框
            const viewer = this.viewer
            //实时更新位置
            viewer.scene.postRender.addEventListener(() => {
                if (this.modal.show) {
                    //转化为屏幕坐标
                    var windowPosition = Cesium.SceneTransforms.wgs84ToWindowCoordinates(viewer.scene, gisPosition);
                    Object.assign(this.modal, {
                        data,
                        style: {
                            left: (windowPosition.x - 130).toFixed(0) + 'px',
                            top: (windowPosition.y - 142).toFixed(0) + 'px'
                        }
                    })

                    //解决滚动不隐藏问题
                    const camerPosition = viewer.camera.position;
                    let height = viewer.scene.globe.ellipsoid.cartesianToCartographic(camerPosition).height;
                    height += viewer.scene.globe.ellipsoid.maximumRadius;
                    if ((!(Cesium.Cartesian3.distance(camerPosition, gisPosition) > height)) && viewer.camera.positionCartographic.height < 50000000) {
                        Object.assign(this.modal, {
                            show: true
                        })
                    } else {
                        Object.assign(this.modal, {
                            show: false
                        })
                    }

                }

            })

        },
        // 相机绕点环绕飞行
        rotateCamera(options) {
            const viewer = this.viewer
            const position = this.setPosition(options.lng, options.lat, options.ele)
            this.rotateBool = true
            // 相机看点的角度，如果大于0则是从地底往上看，所以要为负值，这里取-30度
            let pitch = Cesium.Math.toRadians(-30)
            // 给定飞行一周所需时间，比如30s, 那么每秒转动度数
            var angle = 360 / 30;
            // 给定相机距离点多少距离飞行
            var distance = options.ele || 500;  // options.alt
            var startTime = Cesium.JulianDate.fromDate(new Date());

            var stopTime = Cesium.JulianDate.addSeconds(startTime, 30, new Cesium.JulianDate());

            viewer.clock.startTime = startTime.clone();  // 开始时间
            viewer.clock.stopTime = stopTime.clone();     // 结速时间
            viewer.clock.currentTime = startTime.clone(); // 当前时间
            viewer.clock.clockRange = Cesium.ClockRange.CLAMPED; // 行为方式
            viewer.clock.clockStep = Cesium.ClockStep.SYSTEM_CLOCK; // 时钟设置为当前系统时间; 忽略所有其他设置。
            // 相机的当前heading
            var initialHeading = viewer.camera.heading;
            var rotateExection = function TimeExecution() {
                // 当前已经过去的时间，单位s
                var delTime = Cesium.JulianDate.secondsDifference(viewer.clock.currentTime, viewer.clock.startTime);
                // 根据过去的时间，计算偏航角的变化
                var heading = Cesium.Math.toRadians(delTime * angle) + initialHeading;

                viewer.camera.lookAt(position, new Cesium.HeadingPitchRange(heading, pitch, distance));

                if (Cesium.JulianDate.compare(viewer.clock.currentTime, viewer.clock.stopTime) >= 0) {
                    viewer.clock.onTick.removeEventListener(rotateExection);
                }
            };
            viewer.clock.onTick.addEventListener(rotateExection);
            this.rotateExection = rotateExection
        },
         // 停止环绕
        stopRotate() {
            this.rotateBool = false
            this.viewer.clock.onTick.removeEventListener(this.rotateExection);
        },
        // 鸟瞰图, 俯视
        birdEyeView(options, angle= 45) {
            if(this.rotateBool) {
                this.stopRotate()
            }
            const viewer = this.viewer
            const position = this.setPosition(options.lng, options.lat, options.ele)
            // 相机看点的角度，如果大于0则是从地底往上看，所以要为负值，这里取-30度
            let heading = Cesium.Math.toRadians(50)
            let pitch = Cesium.Math.toRadians(-angle)
            viewer.camera.lookAt(position, new Cesium.HeadingPitchRange(heading, pitch, 100));
        },
        /**移除billboards */
        clearBillboard() {
            this.billboards.removeAll()
        },
        // 加载gpx文件
        loadGPX(url) {
            const viewer = this.viewer
            const that = this
            let xhr = new XMLHttpRequest();
            xhr.open("get", url, false);
            xhr.onload = function() {
                if (xhr.status == 200) {
                    // removeKmlFileData();
                    // removeCzmlFileData();
                    that.removeGpxFileData()
                    // console.log(xhr.response)
                    let dataSource = Cesium.GpxDataSource.load(
                        url,
                        {
                            clampToGround: true
                        }
                    );
                    that.GPXArray.push(dataSource)
                    viewer.dataSources.add(dataSource).then(function(dsource){
                        viewer.flyTo(dsource, {
                            //  飞行时间8秒
                            duration: 8.0,
                            // 调整飞行完成后的摄像机视角
                            offset: new Cesium.HeadingPitchRange(0.0, Cesium.Math.toRadians(-90.0))
                        });
                    });
                    // if (this.response[0] == "<") {
                    //     var dataSourcePromise = Cesium.KmlDataSource.load(url);
                    //     dataSourcePromise.then(function(dataSource) {
                    //         kmlArray.push(dataSource);
                    //         viewer.dataSources.add(dataSource);
                    //         viewer.flyTo(dataSource, {
                    //             // 飞行时间8秒
                    //             duration: 8.0,
                    //             // 调整飞行完成后的摄像机视角
                    //             offset: new Cesium.HeadingPitchRange(0.0, Cesium.Math.toRadians(-90.0))
                    //         });
                    //     })
                    // }
                }
            };
            xhr.send();
        },

        // 加载gpx航迹文件
        gpxLayer(gpxUrl) {
            // 加载GPX文件
            const dataSource = new Cesium.GpxDataSource();
            dataSource.load(gpxUrl, { clampToGround: true }).then(() => {
                this.viewer.dataSources.add(dataSource);
                this.viewer.flyTo(dataSource, {
                    //  飞行时间8秒
                    duration: 8.0,
                    // 调整飞行完成后的摄像机视角
                    offset: new Cesium.HeadingPitchRange(0.0, Cesium.Math.toRadians(-90.0))
                });
            });
        }
    }
}